.thumbnails {
  @apply transition;
  @apply duration-300;
  padding-bottom: 60%;
}
@media (max-width: 768px) {
  .thumbnails-sided {
    padding-bottom: 100%;
  }
}

.news-item-loaded {
  &:hover {
    .thumbnails {
      @apply shadow-lg;
    }
  }
}
