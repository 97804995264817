.thumbnails {
  @apply transition;
  @apply duration-300;
}

.gallery-item-loaded {
  &:hover {
    .thumbnails {
      @apply shadow-lg;
    }
  }
}
